import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getData, postData } from "src/api/AEapi";
import CompletePaymentUI from "./UI/CompletePaymentUI";
import ContentWrap from "./UI/ContentWrap";
import NoSubscriptionUI from "./UI/NoSubscritionUI";
import BubbleLoader from "src/components/loaders/BubbleLoader";
import { AccountContext } from "src/Account";


//UI 
const AdminNoSubscriptionPage = () => {
  const history = useHistory();
  

  const [checkInterval, setCheckInterval] = useState();
  const [activity, setActivity] = useState({});
  const [runChecks, setRunChecks] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [activityStatus, setActivityStatus] = useState(0);
  const [isActivityChecked , setIsActivityChecked] = useState(false)
  const [paymentStatus , setPaymentStatus] = useState(null)
  const [subscripitonStatus , setSubscriptionStatus] = useState(null)
   const [checkingProfile, setCheckingProfile] = useState(false)
   const[cancellingPayment , setCancellingPayment] = useState(false)
  // activityStatus
  // 0 => no sub, no payment,
  // 1 => payment processing
  // 2 => payment incomplete
  // 3 => proce sun, payment done,
  // 4 => sub done, payment done,


  // logout
  const logoutUser = () => {
    localStorage.clear();
    window.location.reload();
  };

  // more plans
  function viewPlans() {
     history.push("/mysubscriptions/viewplans");
  }
 
  // checking active subscription
const isActiveSubscription =  localStorage.getItem('@isActiveSubscription')

useEffect (() => {

  handleRedirectionFromZoho()
  checkSubscriptionActivity()

    if(localStorage.getItem('@isActiveSubscription') === 'true') {
        stopCheckingActivity()
        
        history.push('/dashboard')       
    }

    return () => {
       stopCheckingActivity()
    }

} , [isActiveSubscription , activityStatus , paymentStatus , subscripitonStatus ])



// on redirecting from hosted page 
// we need to read the hosted_id and remove it from url

const handleRedirectionFromZoho = async () => {
    const url = new URL(window.location.href);
    const id = url.searchParams.get("hostedpage_id");

    console.log({
      url , id
    })

    if(id && id !== null ) {
       localStorage.removeItem("@isHostedPageOpened");
        // update payment info 
         try {
          const response = await postData("/subscription/" + id + "/subscription-enquiry" , {})
          if(response.data) {
             console.log("Payment updated with hosted id")
             // delete the param from url
             url.searchParams.delete("hostedpage_id");
             await checkSubscriptionActivity()
          } else {
              console.log("Failed to udate payment info")
          }
         } catch (error) {
            console.log("Error while reading, updatig and removing hostedID" , error)
         }
    }
}

const clearEnquiryStatus = async () => {
  setCancellingPayment(true)
  const clientId = localStorage.getItem("clientId");

  try {
    const response = await postData(
      "/subscription/" + clientId + "/subscription-enquiry/delete",
      {}
    );

    window.location.reload()
   
    console.log("Cancellation response..", response);
    setCancellingPayment(false)
  } catch (error) {
    console.log("Error deleting subscription data ", error);
    setCancellingPayment(false)
  }
};

// checking subscription Activity  #1 

// status codes 
// payment : 1 -> Processing, 2 -> Completed, 3 -> InComplete
// subscription : 1 -> processing, 2 -> active, 3 -> inactive

  const checkSubscriptionActivity = async () => {
     
    // check activity 
    setCheckingStatus(true)
    const clientId = localStorage.getItem("clientId");
    try {

      const response = await getData(`/subscription/${clientId}/subscription-enquiry`);
      if (response.data) {

        setActivity(response.data);

        const _subscriptionStatus = response.data.subscriptionStatus;
        const _paymentStatus = response.data.paymentStatus;
        
        setPaymentStatus(_paymentStatus)
        setSubscriptionStatus(_subscriptionStatus)

        if (_paymentStatus === 1) {
          // processing payment // refresh 5 secs
           setActivityStatus(1)
           startCheckingActivity()
           setIsActivityChecked(true)
           return
        }

        if (_paymentStatus === 3) {
          // incomplete payment // no refrsh ask to make payment // check for subscriptions
          setActivityStatus(2)
          stopCheckingActivity()
          setIsActivityChecked(true)
          return
        }

        if (_paymentStatus === 2) {
          // payment complete 
          setActivityStatus(1)
          startCheckingActivity()
          
          if (_subscriptionStatus === 1) {
            // subscription processing // refresh 5 secs 
            setIsActivityChecked(true)
            return
          }

          if (_subscriptionStatus === 2) {
             setActivityStatus(4)
             stopCheckingActivity()
             await checkProfile()
             setIsActivityChecked(true)
             return
            // subscription active // can break out of this flow and access app // stop refreshing 
          }

          if (_subscriptionStatus === 3) {
            // subscription is inactive 
            setIsActivityChecked(true)
            return
          }


        }

      } else {
        console.log('failed to check status, there is a chance that user has subscription')
        // check profile \\
        clearInterval(checkInterval)
        await checkProfile()
      }

      setCheckingStatus(false)
      setIsActivityChecked(true)
    } catch (error) {
      clearInterval(checkInterval)
      console.log('error checking activity, there is a chance that user has subscription', error)
      setCheckingStatus(false)
      await checkProfile()
      setIsActivityChecked(true)
      // check profile \\
    }

    
  }

  const allowCheck =  paymentStatus === 2 || paymentStatus === 1 || subscripitonStatus === 1
 // an interval function that checks status every 5 sec
   const startCheckingActivity = () => {
    
    const interval = setInterval(async () => {
        if(allowCheck) {
          if( localStorage.getItem('@isActiveSubscription') !== 'true' && !checkingProfile) {
            console.log("Checking profile...")
            await checkProfile() 
           } else {
            console.log("Tried to check profile...")
            if(localStorage.getItem('@isActiveSubscription') === 'true') {
               clearInterval(interval)
            }
           }  
        } else {
           clearInterval(interval)
        }   
    },5000)

    setCheckInterval(interval)
  }

  // breaking the above interval

  const stopCheckingActivity = async () => {
      clearInterval(checkInterval)
      setRunChecks(false);
      setCheckingStatus(false);
      
  }

  const checkProfile = async () => {
    setCheckingProfile(true)
     try {
        const response = await getData("/profile");
        if (response.data) {
          if ( response.data.data &&
               response.data.data.subscription &&
               response.data.data.subscription !== null) {
               
                 const subscripitonStatus = response.data.data.subscription.status

                 if(subscripitonStatus === 1 ) {
                    stopCheckingActivity()
                    localStorage.setItem('@isActiveSubscription' , 'true')
                    localStorage.setItem('subscriptionId',response.data.data.subscription.subscriptionId )
                    window.location.reload()
                 }
               } 

        } else {
          console.log('Failed to check profile..')
        }
        setCheckingProfile(false)
     } catch (error) {
      console.log(error)
      setCheckingProfile(false)
     }
  }


 
 

  // rendering ui based on activity status
  return isActivityChecked === false && checkingStatus === true ? (
    <BubbleLoader />
  ) : (
    <Fragment>    
      {activityStatus === 1 || activityStatus === 3  ? (
        // payment processing // subscription processing
        <ContentWrap>
          <p
            style={{
              fontSize: "20px",
              color: "#000",
              fontWeight: 400,
              textAlign: "center",
              width: "70%",
            }}
          >
            <BubbleLoader />
            Please wait while we retrieve your subscription.
          </p>
        </ContentWrap>
      ) : activityStatus === 2 ? (
        // payment incomplete

        <CompletePaymentUI
          hostedpageExpiryTime={activity.hostedPageExpiresAt}
          planCode={activity.planCode}
          hostedURL={activity.hostedPageUrl}
          onCancel={clearEnquiryStatus}
          cancellingPayment = {cancellingPayment}
        />
      ) :   activityStatus === 4 ? (
        <ContentWrap>
          <p
            style={{
              fontSize: "20px",
              color: "#000",
              fontWeight: 400,
              textAlign: "center",
              width: "70%",
            }}
          >
            Your subscription was successful. Thank you
          </p>
        </ContentWrap>
      ) : (
        <NoSubscriptionUI viewPlans={viewPlans} logoutUser={logoutUser} />
      )}
    </Fragment>
  );
};

export default AdminNoSubscriptionPage;
