import React, { Fragment } from "react";
import NoSubscriptionHeader from "./NoSubscriptionHeader";

function NoSubscriptionLayout({ children }) {
  return (
    <div className="c-app c-default-layout">
       
      <div className="c-wrapper">
        <NoSubscriptionHeader />
        <div className="c-body p-4">
          {children}
        </div>
        
      </div>
    </div>
  );
}

export default NoSubscriptionLayout;
