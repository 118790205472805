import { Fragment } from "react";
import classes from "../stylesheets/plancard.module.css";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
} from "@coreui/react";
import ButtonLoader from "src/components/loaders/ButtonLoader";

const PlanCardComponent = ({
  plan,
  onSubscribe,
  showButtonLoader,
  disableButtons,
  loading
}) => {
 

  const PlanPeriod = ({ months }) => {
    if (months === 1) {
      return <h6>1 Month</h6>;
    }
    if (months === 12) {
      return <h6>1 Year</h6>;
    }
    return <h6>{months}</h6>;
  };

  const getPlanTime = (interval, unit) => {
 
    switch (unit) {
      case "weeks":
        if (interval > 1) {
          return `${interval} weeks`;
        }
        return interval + " week";

      case "months":
        if (interval > 1) {
          return `${interval} months`;
        }
        return interval + " month";

      case "years":
        if (interval > 1) {
          return `${interval} years`;
        }
        return interval + " year";
    }
  };

  //           "intervalUnit": "months",  -
  //           "interval": 0,  -
  //           "name": "Standard",
  //           "planCode": "STD_001",
  //           "productId": null,
  //           "recurringPrice": 599.0,
  //           "status": 1,
  //           "planId": "1315917000000016003"

  return (
    <CCol  xs={12} >
      <CCard  className='d-flex flex-row shadow-sm' >
        {/* Plan Head */}
        <CCardHeader className={[classes.planHeader]} style={{flex:1}}>
          <h5 className="text-dark text-capitalize mb-2">{plan.name}</h5>

          {plan.isRecommended && (
            <div className={classes.recommendedPlan}>Recommended</div>
          )}
          <h4 className="text-dark text-capitalize mb-2">
            &#8377;{plan.recurringPrice}
          </h4>
          <h6>{getPlanTime(plan.interval, plan.intervalUnit)}</h6>

        </CCardHeader>

        
        <CCardFooter className="d-flex align-items-center  justify-content-center w-25">
          <CButton
            disabled={disableButtons === true}
            className=" w-75 font-weight-bold text-white"
            style={{ backgroundColor: "#3c4b64" , minWidth: 120, height : 40  }}
            onClick={() => onSubscribe(plan.planCode)}
          >
            {loading === true ? (
              <ButtonLoader />
            ) : (
              "Subscribe"
            )}
          </CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  );
};

export default PlanCardComponent;
