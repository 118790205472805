import React, { Suspense } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { CContainer } from '@coreui/react'

// routes config
import routes from '../routes'
import { agentDefinedRoutes } from './roleDefinedRoutes'

// spinner - while content load // 
const LoadingSpinner = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

// The rendering content on route change // 
const TheContent = () => {

  let history = useHistory();

  const token = localStorage.getItem('token');

  if (!token) {
    history.push('/login');
  }

  // getting current user allowed paths // 
  const getUserAllowedPaths = (routes) => {

    const allPaths = routes.map(item => item.path)

    switch (localStorage.getItem("roleId")) {
      case "ROLE_SUPER_ADMIN":
        return allPaths
      case "ROLE_ADMIN":
        return allPaths
      case "ROLE_USER":
        const _permittedRoutes = localStorage.getItem('@userPermittedRoutes');
        const permittedRoutes = _permittedRoutes && _permittedRoutes !== null ?
          JSON.parse(_permittedRoutes) : []
          //console.log(permittedRoutes)
        return permittedRoutes
      case "ROLE_SUPPORT_AGENT":
        return agentDefinedRoutes

    }
  }

  // storing above extracted paths/ routes // 
  const userAllowedPaths = getUserAllowedPaths(routes)

  // default navigated path //
  const redirectPath = localStorage.getItem("roleId") === "ROLE_SUPPORT_AGENT" ?
    "/purchase/purchaseinvoicesearch" : "/dashboard"


  // get current user navigation filtered from all routes // 
  // using user allowed paths //
  const currentUserRoutes = routes.filter(item => {
    if (userAllowedPaths && userAllowedPaths.includes(item.path)) {
      return item
    }
  })

  return (
    <main className="c-main">
      <CContainer fluid>
        <Suspense fallback={LoadingSpinner}>
          <Switch>
            {
              currentUserRoutes.map((route, idx) => {
                return route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      token && token !== null
                        ? <route.component {...props} />
                        : <Redirect to={{ pathname: "/login" }} />
                    )}
                  />
                )
              })
            }
            <Redirect from="/" to={token && token !== null ? redirectPath : '/login'} />
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  )
}

export default TheContent
