import { CButton, CCard } from '@coreui/react';
import React from 'react'
import ButtonLoader from 'src/components/loaders/ButtonLoader';
import { TheHeader, TheSidebar } from 'src/containers';

const NoSubscriptionPage = () => {
  const logoutUser = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (




    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 0.8, }}>
      <CCard style={{ width: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '0px solid red', height: "250px" }}>
        <p style={{ fontSize: '20px', color: '#000', fontWeight: 400, }}>You have no active subscription</p>
        <p style={{ fontSize: '20px', color: '#000', fontWeight: 400, marginBottom: '25px' }}>Please contact your company admin</p>
        {/* <Button>Logout</Button> */}
        <CButton type={'button'} color={'danger'} onClick={logoutUser}
          style={{ display: 'flex', justifyContent: 'center', width: '50%' }} >
          {
            false ?
              <ButtonLoader />
              :
              "Logout"
          }
        </CButton >
      </CCard>

    </div>

  );
};

export default NoSubscriptionPage;
