import React from "react";
import {  CButton, CHeader, CHeaderBrand, CHeaderNav, CHeaderNavItem } from "@coreui/react";
import logos from "../../../assets/logo1.png";
import CIcon from "@coreui/icons-react";
 
 

const NoSubscriptionHeader = () => {

  const roleId = localStorage.getItem('roleId')

  const logoutUser = () => {
    localStorage.clear();
    window.location.reload();
  }


  return (
    <CHeader withSubheader>
     
     
    <CHeaderBrand className="mx-md-3  mx-auto ">
      <CIcon name="logo" src={logos} height="48" width="100%" alt="Logo" />
    </CHeaderBrand>

    <CHeaderNav className="d-sm-down-none mr-auto">
      <CHeaderNavItem className="px-3" >
        <h4> AccountEasy - {localStorage.getItem('companyName')}</h4>
      </CHeaderNavItem>
    </CHeaderNav>

    <CHeaderNav className="px-3  ">
      
      {
        roleId === "ROLE_ADMIN" && 
        <CButton variant="outline" color="danger"  className='border-red border-1' onClick={logoutUser}>
           Logout
       </CButton>  

      }
      
      
    </CHeaderNav>

    
  </CHeader>
  );
};

export default NoSubscriptionHeader;
