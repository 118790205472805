import axios from "axios";
import { Auth } from "aws-amplify";
import getAuthToken from "./getAuthToken";
//export const BASE_URL = "http://localhost:8080";
//export const BASE_URL = "https://beta-api.accounteasy.in";
export const BASE_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_TALLY_AGENT_DOWNLOAD_URL =
  process.env.REACT_APP_TALLY_AGENT_DOWNLOAD_URL;

const AEapi = axios.create({
  baseURL: BASE_URL,
  timeout: 29000,
  headers: {
    Content: "Application/json",
  },
});

export default AEapi;

export const postAuthData = async (path, reqBody = {}) => {
  const requestUrl = BASE_URL + path;
  return await AEapi.post(requestUrl, reqBody);
};

export const postData = async (path, reqBody = {}) => {
  const requestUrl = BASE_URL + path;
  
  try {
    let authToken = await getAuthToken();
    const response = await AEapi.post(requestUrl, reqBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error && error.toString().includes("401")) {
      await Auth.signOut();
    }
    return error;
  }
};

export const getData = async (path) => {
  // we can use this token as authorization token
  

  try {
    let authToken = await getAuthToken();
    const response = await AEapi.get(path, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error && error.toString().includes("401")) {
      await Auth.signOut();
    }

    return error;
  }
};

// only We Can Use Login Login Profile Verification Time

export const getProfileData = async (path) => {
  const requestUrl = BASE_URL + path;
  let authToken = await getAuthToken();
  try {
    const response = await AEapi.get(requestUrl, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error && error.toString().includes("401")) {
      await Auth.signOut();
    }

    return error;
  }
};

export const getOtpData = async (path, reqBody = {}) => {
  const requestUrl = BASE_URL + path;
 

  try {
    let authToken = await getAuthToken();
    const response = await AEapi.post(requestUrl, reqBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response;
  } catch (error) {
    if (error && error.toString().includes("401")) {
      await Auth.signOut();
    }
    return error;
  }
};
