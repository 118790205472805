import { Auth } from 'aws-amplify';
import React, { createContext, useState } from 'react';
import {getProfileData} from './api/AEapi';
import { useDispatch } from 'react-redux';

const AccountContext = createContext();

const Account = (props) => {

     const dispatch = useDispatch()
     
    const [resgistationResponse,setRegitationResponse] = useState("");
    const [otp,setOtp] = useState("")


  


    // get current Session
    const getSession = async () => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            if(currentUser){
                localStorage.setItem("token", currentUser.signInUserSession.accessToken.jwtToken);
                await getProfile()
            } else {
             await logout()
            }
        }
        catch (e) {
            console.log(e);
            await logout()
        }
    }

    // Login User or admin
    const signIn = async (emailAddress, password) => {

        try {
            const user = await Auth.signIn(emailAddress, password);
            if (user) {
                return user;
            } else {
                return ""
            }
        }
        catch (e) {
            return ""
        }

    }

    // Get User Profile
    const getProfile = async () => {
        try {
            const response = await getProfileData("/profile");
            if ( response.data && 
                 response.data.status === 'ok' && 
                 response.data.data) {

                localStorage.setItem("userId", response.data.data.userId);
                
                localStorage.setItem("emailId", response.data.data.emailId);
                localStorage.setItem("clientId", response.data.data.clientId);
                localStorage.setItem("roleId", response.data.data.role);
                localStorage.setItem("companyName", response.data.data.companyName);
                localStorage.setItem("loggedIn", true);

                const roleId = localStorage.getItem("roleId");

                if(roleId === 'ROLE_USER'){
                    dispatch({type : 'set' , permissions : response.data.data.permissions});
                }

                if(response.data.data.guid && 
                  response.data.data.guid !== null && 
                  response.data.data.guid !== '') {

                    localStorage.setItem("guid", response.data.data.guid)
                }

                if (roleId !== "ROLE_SUPER_ADMIN") {
                  //  console.log("USER SUBSCRIPTION " , response.data.data.subscription )
                  if (response.data.data.subscription && response.data.data.subscription !== null) {
                    // here, user will have sub
                    const subscriptionData = response.data.data.subscription;
                    if(response.data.data.subscription.status === 1  ) {
                         //console.log("The User has active subscription")
                        localStorage.setItem('subscriptionId' ,response.data.data.subscription.subscriptionId)
                        localStorage.setItem("@isActiveSubscription", "true");
                        dispatch({ type: "set", subscription: subscriptionData });
                    }  else {
                        localStorage.removeItem("@isActiveSubscription");
                        localStorage.removeItem("subscriptionId")
                    }
                      
                  } else {
                     // no sub
                     try {
                        localStorage.removeItem("@isActiveSubscription");
                        localStorage.removeItem("subscriptionId")
                     } catch (error) {
                        console.log('failed to remove items from local', error)
                     }

                     console.log("The current user has no subscription")
                   
                     
                  }
                }           
               
                return response.data.status

            } else {
                return response.data.status
            }
 
                
      }
        catch (e) {
            console.log(e);
            return "error"
        }
    }

    const changePassword = async(user,newPassword)=>{

        try{
          const response = await Auth.completeNewPassword(
                user, // the Cognito User Object
                newPassword, // the new password
              )
              if(response){
                return response;
              }else{
                return "error"
              }
        }catch(e){
            return "error"
        }
        
    }


    const OtpVerification = async (otp)=>{
        setOtp(otp)
    }


    // LogOut Profile
    const logout = async() => {
        Auth.signOut()
        .then(async (result) =>{
            localStorage.clear();
        })
        .catch((err) => {});
    }


    const sendDataToOtpScreen = (clientId,userId,userName,password)=>{
        setRegitationResponse({clientId,userId,userName,password})
        return {clientId,userId}
    }

   

    return (
        <AccountContext.Provider value={{ signIn, getProfile, getSession, logout,sendDataToOtpScreen,
                                          resgistationResponse,changePassword,OtpVerification,otp }}>
            {props.children}
        </AccountContext.Provider>
    )
}

export { Account, AccountContext }