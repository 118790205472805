import React from "react";

const ContentWrap = ({ children }) => {
  return (
    <div className="d-flex flex-column align-items-center h-100 justify-content-center p-4 ">
      <div
        className="d-flex flex-column align-items-center justify-content-center p-2 bg-white shadow-sm  "
        style={{ minHeight: 250, minWidth: 600 }}
      >
        {children}
      </div>
    </div>
  );
};

export default ContentWrap;
