import { CButton} from "@coreui/react"
import React from "react"
import { Modal, ModalBody, ModalFooter } from "react-bootstrap"
import ModalHeader from "react-bootstrap/esm/ModalHeader"


const AlertModal = ({ visible, onHide, message  , showClose = true  }) => {
    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            fullscreen={false}
            show={visible}
            onHide={onHide}
        >
            <ModalHeader closeButton>Alert!</ModalHeader>
            <ModalBody>
                <b>
                    {message && message.split("\n").map((str, index) => (
                        <p key={index}>{str}</p>  
                    ))}
                </b>
            </ModalBody>
            {
                showClose === true  && 
     
            <ModalFooter>
                <CButton
                    size="sm"
                    onClick={onHide}
                    color="danger"
                >
                    Close
                </CButton>
            </ModalFooter>
        }
        </Modal>
    )
}


export default AlertModal;