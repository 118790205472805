import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'
import {   HashRouter } from 'react-router-dom';
import { Account } from './Account';


React.icons = icons

ReactDOM.render(
  
    <Provider store={store}>
      <Account> 
      <HashRouter>
        <App />
      </HashRouter>
      </Account>
    </Provider>
  
,
  document.getElementById('root')
);
serviceWorker.unregister();
