import { Auth } from "aws-amplify";

export default async function getAuthToken() {
  let token;
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (currentUser) {
      token = currentUser.signInUserSession.accessToken.jwtToken;
    } else {
      await Auth.signOut();
    }
  } catch (error) {
    console.log(error, "This error occured getting current user");
    await Auth.signOut();
  }

  return token;
}
