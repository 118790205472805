import { CButton } from "@coreui/react";
import React from "react";
import ButtonLoader from "src/components/loaders/ButtonLoader";
import ContentWrap from "./ContentWrap";

const NoSubscriptionUI = ({ viewPlans, logoutUser }) => {
  return (
    <ContentWrap>
      <p style={{ fontSize: "20px", color: "#000", fontWeight: 400 }}>
        You have no active subscription.
      </p>

      <p style={{ fontSize: "20px", color: "#000", fontWeight: 400 }}>
        Please choose a plan to subscribe and access AccountEasy
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          marginTop: 30,
        }}
      >
        <CButton
          type={"button"}
          color={"primary"}
          onClick={viewPlans}
          style={{ display: "flex", justifyContent: "center", width: "25%" }}
        >
          {false ? <ButtonLoader /> : "View Plans"}
        </CButton>
 
      </div>
    </ContentWrap>
  );
};

export default NoSubscriptionUI;
