import React from 'react'
import CIcon from '@coreui/icons-react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
const role = Number(localStorage.getItem('roleId'));
let show = [];
const _nav = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Settings',
    route: '#',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'User Master',
        to: '/settings/users',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Tag Master',
        to: '/bank/tagmaster',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Bank Category',
        to: '/settings/categorymaster',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Company',
        to: '/companycreation',
      },
      
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Agent',
      //   to: '/agentcreation',
      // },
      {
        _tag: 'CSidebarNavItem',
        name: 'GUID',
        to: '/guid',
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Ledger',
    to: '/ledger',
    icon: <CIcon name="cil-list" customClasses="c-sidebar-nav-icon" />,
  }, {
    _tag: 'CSidebarNavDropdown',
    name: 'Purchases & Expenses',
    route: '/purchase',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Upload Documents',
        to: '/purchase/purchasedocsearch',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Process Documents',
        to: '/purchase/purchaseinvoicesearch',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Sales',
    route: '/sales',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Upload Documents',
        to: '/sales/salesdocsearch',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Process Documents',
        to: '/sales/salesinvoicesearch',
      },
    ],
  },

  {
    _tag: 'CSidebarNavDropdown',
    name: 'Bank',
    to: '/bank',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Create Bank Account',
        to: '/bank/accountcreation',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Upload Bank Statement',
        to: '/bank/bankstatementupload',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Transaction Accounting',
        to: '/bank/accounttransaction',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Transaction Mapping',
        to: '/bank/reconcile',
      },
    ],
  },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Subscriptions',
  //   route: '/subscriptions',
  //   icon: <BookmarksOutlinedIcon className="c-sidebar-nav-icon" fontSize='small'/>,
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Create Plans',
  //       to: '/subscriptions/createplans',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Manage Plans',
  //       to: '/subscriptions/manageplans',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Manage Subscriptions',
  //       to: '/subscriptions/manageallsubscriptions',
  //     },
  //   ],
  // },
  {
    _tag: "CSidebarNavItem",
    name: "Profile",
    to: "/profile",
    icon: <CIcon name="cilPeople" customClasses="c-sidebar-nav-icon" />,
  },
 
  {
    _tag: "CSidebarNavItem",
    name: "Help",
    to: "/help",
    icon: <HelpOutlineIcon className="c-sidebar-nav-icon"/>,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Logout',
    to: '/logout',
    icon: <CIcon name="cil-lock-locked" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: 'CSidebarNavDivider',
    className: 'm-2' 
  }
]

const _nav2 = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Settings',
    route: '#',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'User Master',
        to: '/settings/users',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Tag Master',
        to: '/bank/tagmaster',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Bank Category',
        to: '/settings/categorymaster',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Company',
        to: '/companycreation',
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Ledger',
    to: '/ledger',
    icon: <CIcon name="cil-list" customClasses="c-sidebar-nav-icon" />,
  }, {
    _tag: 'CSidebarNavDropdown',
    name: 'Purchases & Expenses',
    route: '/purchase',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Documents Uploaded',
        to: '/purchase/purchasedocsearch',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Invoices',
        to: '/purchase/purchaseinvoicesearch',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Sales',
    route: '/sales',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Documents Uploaded',
        to: '/sales/salesdocsearch',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Invoices',
        to: '/sales/salesinvoicesearch',
      },

    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Bank',
    to: '/bank',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Account Creation',
        to: '/bank/accountcreation',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Statement Upload',
        to: '/bank/bankstatementupload',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Transaction Accounting',
        to: '/bank/accounttransaction',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Transaction Mapping',
        to: '/bank/reconcile',
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Logout',
    to: '/logout',
    icon: <CIcon name="cil-lock-locked" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: 'CSidebarNavDivider',
    className: 'm-2'
  }
]

const _nav3 = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Ledger',
    to: '/ledger',
    icon: <CIcon name="cil-list" customClasses="c-sidebar-nav-icon" />,
  }, {
    _tag: 'CSidebarNavDropdown',
    name: 'Purchases & Expenses',
    route: '/purchase',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Documents Uploaded',
        to: '/purchase/purchasedocsearch',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Invoices',
        to: '/purchase/purchaseinvoicesearch',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Sales',
    route: '/sales',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Documents Uploaded',
        to: '/sales/salesdocsearch',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Invoices',
        to: '/sales/salesinvoicesearch',
      },

    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Bank',
    to: '/bank',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Account Creation',
        to: '/bank/accountcreation',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Statement Upload',
        to: '/bank/bankstatementupload',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Transaction Accounting',
        to: '/bank/accounttransaction',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Transaction Mapping',
        to: '/bank/reconcile',
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Logout',
    to: '/logout',
    icon: <CIcon name="cil-lock-locked" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: 'CSidebarNavDivider',
    className: 'm-2'
  }
]

show = _nav;
if (role === 1) {
  show = _nav
} else if (role === 2) {
  show = _nav2
} else if (role === 3) {
  show = _nav3
}

export default _nav

