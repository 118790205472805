import React from 'react'
import { useHistory } from 'react-router-dom';
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from './index'

const TheLayout = ( ) => {
  let mins = 360;
  let saved = localStorage.getItem('sessiontime')
  if (saved && (new Date().getTime() - saved > (mins * 60) * 1000)) {
    localStorage.clear()
  }
  let history = useHistory();
  const token = localStorage.getItem('token');
  if (!token) {
    history.push('/login');
  }

  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <TheContent />
        </div>
        <TheFooter />
      </div>
    </div>
  )
}

export default TheLayout
