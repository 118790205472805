const awsConfig = {
  aws_project_region: "ap-south-1",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_iJQ20alOz",
  aws_user_pools_web_client_id: "3pis69taj7inpgbhhpko7fhmpk",
  aws_cognito_login_mechanisms: ["EMAIL"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_cognito_region: "ap-south-1",
};
export default awsConfig;
