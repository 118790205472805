// NOTE: 
//MAKE CHANGES CAREFULLY IN THIS FILE.

import React, { lazy } from 'react';
//auth
//logout 
const logout = React.lazy(() => import('./views/pages/login/Logout'));
const changepassword = React.lazy(() => import('./views/masters/profile/components/ChangePassword'));
//dashboard
const DashboardNew = React.lazy(() => import('./views/dashboard/DashboardNew'));
//settings
const Users = React.lazy(() => import('./views/masters/userMaster/Users'));
const tagmaster = React.lazy(() => import('./views/masters/tagMaster/TagMaster'));
const categorymaster = React.lazy(() => import('./views/masters/bankCategory/CategoryMaster'));
const companycreation = React.lazy(() => import('./views/masters/companyCreation/CompanyCreation'));
const agent = React.lazy(() => import('./views/masters/agentMaster/AgentCreation'));
const guidList = React.lazy(() => import('./views/masters/GuidList'));
 
//expense and purchase
const purchasedocsearch = React.lazy(() => import('./views/purchase/PurchaseDocumentSearch'));
const purchaseinvoicesearch = React.lazy(() => import('./views/purchase/PurchaseInvoiceSearch'));
//sales
const salesinvoicesearch = React.lazy(() => import('./views/sales/SalesInvoiceSearch'));
const salesdocsearch = React.lazy(() => import('./views/sales/SalesDocumentSearch'));
//banking
const bankaccount = React.lazy(() => import('./views/bank/BankAccount'));
const bankstatementupload = React.lazy(() => import('./views/bank/BankStatementUpload'));
const accounttransaction = React.lazy(() => import('./views/bank/AccountTransaction'));
const Reconcile = React.lazy(() => import('./views/bank/Reconcile'));
const invoicedoc = React.lazy(() => import('./views/transaction/InvoiceDocCompare'));
const invoicedocsales = React.lazy(() => import('./views/transaction/InvoiceDocCompareSales'));
//profile
const Profile = React.lazy(() => import('./views/masters/profile'));
const helpAndSupport = React.lazy(() => import('./views/helpAndSupport/HelpAndSupport'));
//subscriptions
// admin pages 
const mySubscriptionsPage = lazy(() => import('./views/subscriptions/pages/admin/SubscriptionManagementPage'));
const viewPlansPage = lazy(() => import('./views/subscriptions/pages/admin/SubscriptionPlansPage'))
const adminNoSubsctiptionPage = lazy(() => import('./views/subscriptions/pages/admin/AdminNoSubscriptionPage'))
// user 
const userNoSubscriptionPage = lazy(() => import('./views/subscriptions/pages/user/NoSubscritionPage'));
// ledgers masters 
const Ledger = React.lazy(() => import('./views/ledger/Ledger'));
const defaultLedgersPage = lazy(() => import("./views/ledgerMasters/defaultLedgers/DefaultLedgersPage"))
const supplierMasterPage = lazy(() => import("./views/ledgerMasters/supplierMaster/SupplierMasterPage"))
const customerMasterPage = lazy(() => import("./views/ledgerMasters/customerMaster/CustomerMasterPage"))

const userRole = localStorage.getItem('roleId')

const routes = [

  { path: '/dashboard', exact: true, name: 'Dashboard', component: DashboardNew },
  { path: '/settings/companyprofile', exact: true, 
    name: userRole === 'ROLE_ADMIN' ? "Company Profile" : "Profile", component: Profile },
  { path: '/profile', exact: true, 
    name:  localStorage.getItem("roleId") === 'ROLE_ADMIN' ? "Company Profile" : "Profile", component: Profile },
  { path: '/help', exact: true, name: 'Help', component: helpAndSupport },
  { path: '/settings/users', exact: true, name: 'Users Details', component: Users },
  { path: '/bank/tagmaster', name: 'Tag Master', component: tagmaster },
  { path: '/settings/categorymaster', name: 'Bank Category', component: categorymaster },
  { path: '/agentcreation', name: 'Agent Master', component: agent },
  { path: '/companycreation', name: 'Company Master', component: companycreation },
  { path: '/guid', name: 'GUID List', component: guidList },
  { path: '/ledger', name: 'Ledger', component: Ledger },
  { path: '/purchase/purchasedocsearch', name: 'Purchases & Expenses: Upload Documents', component: purchasedocsearch },
  { path: '/purchase/purchaseinvoicesearch', name: 'Purchases & Expenses: Process Documents', component: purchaseinvoicesearch },
  { path: '/transaction/invoicedoc', name: 'Invoice Data Validation', component: invoicedoc },
  { path: '/transaction/invoicedocsales', name: 'Invoice Data Validation', component: invoicedocsales },
  { path: '/sales/salesdocsearch', name: 'Sales: Upload Documents', component: salesdocsearch },
  { path: '/sales/salesinvoicesearch', name: 'Sales: Process Documents', component: salesinvoicesearch },
  { path: '/bank/accountcreation', name: 'Bank Account Creation', component: bankaccount }, 
  { path: '/bank/accounttransaction', name: 'Transaction Accounting', component: accounttransaction },  
  { path: '/bank/bankstatementupload', name: 'Bank Statement Upload', component: bankstatementupload },
  { path: '/bank/reconcile', name: 'Transaction Mapping', component: Reconcile },
  {path : '/mysubscriptions' ,exact : true,  name:'My Subscriptions' , component : mySubscriptionsPage },
  {path : '/mysubscriptions/viewplans' , exact : true, name:'Plans' , component : viewPlansPage },
    // no subscription page 
   { path:'/nosubsription' , name: "No Active Subscription" ,
     component : userRole === 'ROLE_USER' ? userNoSubscriptionPage : adminNoSubsctiptionPage},
  { path: '/logout', name: 'Logout', component: logout },
  { path: '/changepassword', name: 'Change Password', component: changepassword },
  // ledger master pages
  {path : '/ledgers/defaultledgers' , name : 'Default Ledgers' , component : defaultLedgersPage},
  {path : '/ledgers/suppliermaster' , name : 'Supplier Master' , component : supplierMasterPage},
  {path : '/ledgers/customermaster' , name : 'Customer Master' , component : customerMasterPage},

];

export default routes;
