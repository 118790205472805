import React from "react";
import ButtonLoader from "src/components/loaders/ButtonLoader";
import { CButton } from "@coreui/react";
import ContentWrap from "./ContentWrap";
import moment from "moment";
import { getData, postData } from "src/api/AEapi";
import { useHistory } from "react-router-dom";

const CompletePaymentUI = ({ planCode, hostedURL, hostedpageExpiryTime , onCancel , cancellingPayment }) => {

 

  const makePayment = () => {
    if (hostedURL) {
      window.open(hostedURL, "_self");
    }
  };

  const paymentExpiryDate = moment(hostedpageExpiryTime).format("LLL")
 

  return (
    <ContentWrap>
      <p
        style={{
          fontSize: "20px",
          color: "#000",
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        You have an incomplete payment for {planCode}
      </p>

      <p
        style={{
          fontSize: "18px",
          color: "#000",
          fontWeight: 400,
           
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        Please complete the payment to subscribe
      </p>

      <p className="mb-5" 
      style={{
          fontSize: "16px",
          color: "#000",
          fontWeight: 400,     
          textAlign: "center",
        }}>
        The payment link will expire on {paymentExpiryDate}
        </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: 25,
          width: "100%",
        }}
      >
        <CButton
          type={"button"}
          color={"primary"}
          onClick={makePayment}
          style={{ display: "flex", justifyContent: "center", width: "25%" }}
        >
          {false ? <ButtonLoader /> : "Make Payment"}
        </CButton>

        {/* TODO : IF I SELECT NEW PLAN NOT WORKING... */}

        <CButton
          type={"button"}
          color={"danger"}
          onClick={onCancel}
          style={{ display: "flex", justifyContent: "center", width: "25%" }}
        >
          {cancellingPayment ? <ButtonLoader /> : "Cancel Payment"}
        </CButton>  
      </div>
    </ContentWrap>
  );
};

export default CompletePaymentUI;
