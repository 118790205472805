import React from "react";
import CIcon from "@coreui/icons-react";

const agentNavigation = [
  {
    _tag: "CSidebarNavItem",
    name: "Purchase Invoices",
    to: "/purchase/purchaseinvoicesearch",
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Sales Invoices",
    to: "/sales/salesinvoicesearch",
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Profile",
    to: "/profile",
    icon: <CIcon name="cilPeople" customClasses="c-sidebar-nav-icon" />,
  },
   
  {
    _tag: 'CSidebarNavItem',
    name: 'Logout',
    to: '/logout',
    icon: <CIcon name="cil-lock-locked" customClasses="c-sidebar-nav-icon" />,
  },
];


export default agentNavigation;
