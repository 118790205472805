import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
 
import CIcon from "@coreui/icons-react";
import logos from "../assets/logo1.png";
import { getUserPermittedNavigation, saveUserPathsToLocal } from "./role_userNavigationHandler";
import SuperAdminNav from "./_nav";
import AdminNav from "./_nav2";
import agentNavigation from "./_nav4";


const TheSidebar = () => {

  const dispatch = useDispatch();
  const show = useSelector((state) => state.sidebarShow);
  const customPermissions = useSelector((store) => store.permissions);

 
  // The Side Bar
  const sideNavData = () => {
    const roleId = localStorage.getItem("roleId");

    let userPermittedNavigation = [];
    userPermittedNavigation = getUserPermittedNavigation(customPermissions);
    
    switch (roleId) {
      case "ROLE_SUPER_ADMIN":
        return SuperAdminNav;
      case "ROLE_ADMIN":
         return AdminNav;
      case "ROLE_USER":
         saveUserPathsToLocal(userPermittedNavigation , customPermissions);
         return userPermittedNavigation;
      case "ROLE_SUPPORT_AGENT" :
        return agentNavigation

      default:
        return userPermittedNavigation;
    }
  };


 const  navigation =  localStorage.getItem('roleId') === "ROLE_USER" ? 
                            customPermissions ? sideNavData() : [] : sideNavData()

             

  
  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch({ type: "set", sidebarShow: val })}
    >
      <CSidebarBrand className="d-md-down-none" to="/dashboard">
        <CIcon
          className="c-sidebar-brand-full"
          name="logo-negative"
          src={logos}
          height={55}
          width={110}
        />

        <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        />
      </CSidebarBrand>

      <CSidebarNav>
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};


export default React.memo(TheSidebar);
