import React, { Fragment, useEffect, useState } from "react";
import { CButton, CCard,CCardBody, CCardHeader, CModal,
         CModalBody, CModalFooter, CModalHeader, CRow,} from "@coreui/react";
import PlanCardComponent from "../../components/PlanCardComponent";
import classes from "../../stylesheets/subscriptionStyles.module.css";
import ButtonLoader from "src/components/loaders/ButtonLoader";
import { useHistory } from "react-router-dom";
import SmallButton from "src/components/buttons/SmallButton";
import BubbleLoader from "src/components/loaders/BubbleLoader";
import { getData, postData } from "src/api/AEapi";
import AlertModal from "src/views/purchase/components/popups/AlertModal";


// functionaliy : fetch plans to display them 
// make subscriptions
// checks: 
// while making subscription check whether the user has existing one 
// in lifecycle check subscription status and if found processing go to processing page 
// 


const SubscriptionsPlansPage = ({}) => {
  let history = useHistory();
  const [fetchedPlans, setFetchedPlans] = useState([]);
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [subscribing , setSubscribing] = useState({id : 0 , loading : false})
  const [errorMessages, setErrorMessages] = useState([]);
  const [checkingPendingSubscription, setCheckingPendingSubscription] = useState(false)
  
  // this is used to view an alert when a active user tries to subscribe again
  const [showAlert, setShowAlert] = useState(false);
  const [showBlockedAlert , setShowBlockedAlert] = useState(false) 

  const fetchPlans = async () => {
    setLoadingPlans(true);
    try {
      const response = await getData("/subscription/plans");
      // console.log(response.data)

      if (response.data && response.data.data) {
        const allPlans = response.data.data;
        console.log(allPlans);
        if (allPlans && allPlans.length > 0) {
          const plansToDisplay = allPlans.filter(
            (plan) => plan.recurringPrice > 0 && plan.status === 1
          );
          console.log(plansToDisplay);
          setFetchedPlans(plansToDisplay);
        }
        setLoadingPlans(false);
      } else {
        addToErrorGroup("Unable to load Plans.")
        // show error message here
        setLoadingPlans(false);
      }
    } catch (error) {
      console.log("Error while fetching plans ", error);
      // show error message here
      addToErrorGroup("Unable to load Plans.")
      setLoadingPlans(false);
    }
  };

  async function onSelectSubscriptionPaln(planCode) {
    console.log("THe plan code ", planCode);

    console.log("The current user ststus for subscription " ,
                 localStorage.getItem("@isActiveSubscription"))

    if (localStorage.getItem("@isActiveSubscription") === "true") {
       // open a popup showing your new sub wil cancel old
      setShowAlert(true);
     } else {
      // make subscription with selected package
      await subscribe(planCode);
    }
  }

  // handle subscripiton
  const subscribe = async (planCode) => {
    setSubscribing({id:planCode , loading:true})
    if (showAlert === true) {
      setShowAlert(false);
    }
   // make api calls here.
 
    try {
      const requestBody = {
        clientId : localStorage.getItem('clientId'),
        planCode: planCode,
      };

      const response = await postData("/subscription/create", requestBody);

      if (response.data ) {
          if( response.data.code === 0 &&
              response.data.hostedpage !== null
          ) {
          
            // TODO : Make the subscription status processing and payment to incomplete
             
            const hostedUrl = response.data.hostedpage.url;
              if (hostedUrl) {
                localStorage.setItem("@isHostedPageOpened", 'true')
                window.open(hostedUrl, "_self"); 
              } else {
                // show error not avaiable for payment
              }
          }  
 
          if(response.data.code === 400) {
             setShowBlockedAlert(true)
          }
          
        }
      setSubscribing({id:0 , loading:false})
    } catch (error) {
      // show something went wrong
      addToErrorGroup("Something went swrong.")
      setSubscribing({id:0 , loading:false})
       console.log("Error while making a subscription " , error)
    }
  };

  useEffect(() => {
    checkForPendingSubscription()
    fetchPlans()
  },[])


  const checkForPendingSubscription = async () => {
  
    const clientId = localStorage.getItem('clientId')
     if(localStorage.getItem('@isActiveSubscription') !== 'true') {
       setCheckingPendingSubscription(true)
        try {
           const response = await getData('/subscription/' + clientId + '/subscription-enquiry') 
 
           if(response.data) {
             history.replace('/')
           } 
           setCheckingPendingSubscription(false)
        } catch (error) {
          setCheckingPendingSubscription(false)
        }
     }
  }

  const addToErrorGroup = (message) => {
    if (message && errorMessages.includes(message)) {
      console.log("Already in group...");
    } else {
      errorMessages.push(message);
    }
  };

  


  // going back to subscription page
  const navigateBack = () => {
    history.replace("/mysubscriptions");
  };

  function logoutUser() {
    localStorage.clear();
    window.location.reload();
  }

  return checkingPendingSubscription || loadingPlans ? (
    <BubbleLoader />
  ) : 
  


  (
    <Fragment>
      <div>


      <CCard className="mx-auto" style={{minWidth : 600 , maxWidth : 780 , alignSelf : 'center' , flex:1}}>
        <CCardHeader className={`${classes.cardHeader}`}>
          <p className={classes.cardHeaderTitle}>Select a plan</p>
        </CCardHeader>

        <CCardBody>
          <CRow>
            {fetchedPlans &&
              fetchedPlans.length > 0 &&
              fetchedPlans.map((plan) => (
                <PlanCardComponent
                  plan={plan}
                  key={plan.planId}
                  onSubscribe={onSelectSubscriptionPaln}
                  disableButtons={subscribing.loading === true}
                  loading = {plan.planCode === subscribing.id && subscribing.loading === true}
                />
              ))}

            
          </CRow>
        </CCardBody>
      </CCard>

      {/* Alert for blocking subscription */}

      <CModal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showAlert}
        onClose={() => setShowAlert(false)}
      >
        <CModalHeader closeButton>Active subscription found!</CModalHeader>
        <CModalBody className={"border border-1"}>
          <h6>
            You have an active subscription. Please cancel your active
            subscription to proceed
          </h6>
        </CModalBody>

        <CModalFooter style={{ display: "flex", justifyContent: "end" }}>
          <SmallButton
            color={"primary"}
            onClick={navigateBack}
            label="Ok"
          />
           
        </CModalFooter>
      </CModal>


        <AlertModal 
          message="Unable to make subscription, Please contact support."
          visible={showBlockedAlert}
          onHide={() => setShowBlockedAlert(false)}
      />

      
</div>
    </Fragment>
  );
};

export default SubscriptionsPlansPage;
