import React from 'react'
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import ButtonLoader from '../loaders/ButtonLoader';


const SmallButton = ({ color, onClick, loader, label, iconName , disabled , width, size }) => {
    
    return (
 
        <div style={{ width: width ? width :'70px' }}>
            <CButton type={'button'} size={ size? size:'sm'} color={color} onClick={onClick} style={{ display: 'flex', justifyContent: 'center', width: '100%' }} disabled={disabled} >
                {
                    loader ?
                        <ButtonLoader/>
                        :
                        iconName  ?
                            <div className='d-flex align-items-center'>
                                  <CIcon name={iconName} size='sm' className='mr-1' /> {label}
                            </div>
                            :
                        label 
                }
            </CButton >
        </div >

    )
}

export default SmallButton