import React from 'react'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const TheHeaderDropdown = () => {

  const history = useHistory()

  const onLogout = async () => {
     try {
         const response = await Auth.signOut()
         localStorage.clear()
         history.push("/")
     } catch (error) {
        console.og("Error while logour ... " , error)
     }
  }

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CIcon
            src={'avatars/1.jpg'}
            className="c-avatar-img"
            alt=""
          />
        </div>
      </CDropdownToggle>

      <CDropdownMenu>
            <CDropdownItem className='bg-danger' onClick={onLogout}>Logout</CDropdownItem>  
        </CDropdownMenu>

    </CDropdown>
  )
}

export default TheHeaderDropdown
