import { CRow } from "@coreui/react"


const BubbleLoader = () => {
   return (
    <CRow className='justify-content-center align-items-center'>
     <div className="spinner-grow text-warning" role="status"></div>
     <div style={{width : 10}}/>
     <div className="spinner-grow text-secondary" role="list"></div>
     <div style={{width : 10}}/>
     <div className="spinner-grow text-success" role="status"></div>
     {/* <div style={{width : 10}}/> */}
     {/* <div style={{width : 10}}/> */}
     {/* <h5 className="p-0 m-0">Loading.....</h5> */}
    </CRow>
    
   )
} 

export default BubbleLoader