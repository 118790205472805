import React from "react";
import CIcon from "@coreui/icons-react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

// icons used
export const navIcons = {
  dashboard: (
    <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />
  ),
  settings: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
  ledgers: <CIcon name="cil-list" customClasses="c-sidebar-nav-icon" />,
  purchase: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
  sales: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
  bank: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
  profile: <CIcon name="cilPeople" customClasses="c-sidebar-nav-icon" />,
  help: <HelpOutlineIcon className="c-sidebar-nav-icon" />,
  logOut: <CIcon name="cil-lock-locked" customClasses="c-sidebar-nav-icon" />,
};

// getting settings related navigation
const getSettingsNavigation = (permissions) => {
  const settingsNavigation = [];

  permissions.map((permission) => {
    if (
      permission.permission === "P_ADD_UPDATE_USERS" &&
      permission.enabled === true
    ) {
      settingsNavigation.push({
        _tag: "CSidebarNavItem",
        name: "User Master",
        to: "/settings/users",
      });
    }
    return "";
  });

  // if permission find, abobe adding the route to child
  // here creating a group of navigation to createElement
  if (settingsNavigation.length > 0) {
    return {
      _tag: "CSidebarNavDropdown",
      name: "Settings",
      route: "#",
      icon: navIcons.settings,
      _children: settingsNavigation,
    };
  }
};

// getting purchase related navigation
const getPurchaseNavigation = (permission) => {
  const purchaseNavigation = [];

  permission.map((permission) => {
    if (
      permission.permission === "P_PUR_EXP_UPLOAD_DOCS" &&
      permission.enabled === true
    ) {
      purchaseNavigation[0] = {
        _tag: "CSidebarNavItem",
        name: "Upload Documents",
        to: "/purchase/purchasedocsearch",
      };
    }

    if (
      permission.permission === "P_PUR_EXP_VALIDATE_DOCS" &&
      permission.enabled === true
    ) {
      purchaseNavigation[1] = {
        _tag: "CSidebarNavItem",
        name: "Process Documents",
        to: "/purchase/purchaseinvoicesearch",
      };
    }
    return "";
  });
  // above pushed all permitted route to an array
  // below creating a object with children containing above navs
  // createElement will take this as input and render us side nav

  if (purchaseNavigation.length > 0) {
    return {
      _tag: "CSidebarNavDropdown",
      name: "Purchases & Expenses",
      route: "/purchase",
      icon: navIcons.purchase,
      _children: purchaseNavigation,
    };
  }
};

// getting sales related navigation
const getSalesNavigation = (permissions) => {
  const salesNavigation = [];
  permissions.map((permission) => {
    if (
      permission.permission === "P_SALES_UPLOAD_DOCS" &&
      permission.enabled === true
    ) {
      salesNavigation[0] = {
        _tag: "CSidebarNavItem",
        name: "Upload Documents",
        to: "/sales/salesdocsearch",
      };
    }

    if (
      permission.permission === "P_SALES_VALIDATE_DOCS" &&
      permission.enabled === true
    ) {
      salesNavigation[1] = {
        _tag: "CSidebarNavItem",
        name: "Process Documents",
        to: "/sales/salesinvoicesearch",
      };
    }
    return "";
  });

  if (salesNavigation.length > 0) {
    return {
      _tag: "CSidebarNavDropdown",
      name: "Sales",
      route: "/sales",
      icon: navIcons.sales,
      _children: salesNavigation,
    };
  }
};

// getting bank related navigation
const getBankNavigation = (permissions) => {
  const bankNavigation = [];
  permissions.map((permission) => {
    if (
      permission.permission === "P_BANKING_CREATE_ACCOUNTS" &&
      permission.enabled === true
    ) {
      bankNavigation[0] = {
        _tag: "CSidebarNavItem",
        name: "Create Bank Account",
        to: "/bank/accountcreation",
      };
    }

    if (
      permission.permission === "P_BANKING_UPLOAD_STATEMENTS" &&
      permission.enabled === true
    ) {
      bankNavigation[1] = {
        _tag: "CSidebarNavItem",
        name: "Upload Bank Statements",
        to: "/bank/bankstatementupload",
      };
    }

    if (
      permission.permission === "P_BANKING_TXN_ACCOUNTING" &&
      permission.enabled === true
    ) {
      bankNavigation[2] = {
        _tag: "CSidebarNavItem",
        name: "Transaction Accounting",
        to: "/bank/accounttransaction",
      };
    }

    if (
      permission.permission === "P_BANKING_TXN_MAPPING" &&
      permission.enabled === true
    ) {
      bankNavigation[3] = {
        _tag: "CSidebarNavItem",
        name: "Transaction Mapping",
        to: "/bank/reconcile",
      };
    }

    return "";
  });

  if (bankNavigation.length > 0) {
    return {
      _tag: "CSidebarNavDropdown",
      name: "Bank",
      to: "/bank",
      icon: navIcons.bank,
      _children: bankNavigation,
    };
  }
};

// returning all user permitted navigation items
// used in main(sidebar component)
export const getUserNavigation = (permissions) => {
  if (permissions) {
    const settingsNavigation = getSettingsNavigation(permissions);
    const purchaseNavigation = getPurchaseNavigation(permissions);
    const salesNavigation = getSalesNavigation(permissions);
    const bankNavigation = getBankNavigation(permissions);

    return {
      settingsNav: settingsNavigation,
      purchaseNav: purchaseNavigation,
      salesNav: salesNavigation,
      bankNav: bankNavigation,
    };
  }

  return {};
};

// creating a side nav object

export const getUserPermittedNavigation = (permissions) => {
  const userNavigation = getUserNavigation(permissions);
  const { settingsNav, bankNav, salesNav, purchaseNav } = userNavigation;

  const userSideNav = [];
  // DashBoard
  userSideNav[0] = {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/dashboard",
    icon: navIcons.dashboard,
  };

  // Ledgers
  userSideNav[2] = {
    _tag: "CSidebarNavItem",
    name: "Ledger",
    to: "/ledger",
    icon: navIcons.ledgers,
  };

  let settingsChecked = false;
  let purchaseChecked = false;
  let salesChecked = false;
  let bankChecked = false;

  permissions &&
    permissions.forEach((permission) => {
      // settings
      if (
        permission.permission === "P_ADD_UPDATE_USERS" &&
        permission.enabled === true
      ) {
        if (!settingsChecked) {
          userSideNav[1] = settingsNav;
          settingsChecked = true;
        }
      }

      // purchase and Expence
      if (
        (permission.permission === "P_PUR_EXP_UPLOAD_DOCS" &&
          permission.enabled === true) ||
        (permission.permission === "P_PUR_EXP_VALIDATE_DOCS" &&
          permission.enabled === true)
      ) {
        if (!purchaseChecked) {
          userSideNav[3] = purchaseNav;
          purchaseChecked = true;
        }
      }

      // sales
      if (
        (permission.permission === "P_SALES_UPLOAD_DOCS" &&
          permission.enabled === true) ||
        (permission.permission === "P_SALES_VALIDATE_DOCS" &&
          permission.enabled === true)
      ) {
        if (!salesChecked) {
          userSideNav[4] = salesNav;
          salesChecked = true;
        }
      }

      // bank
      if (
        (permission.permission === "P_BANKING_CREATE_ACCOUNTS" &&
          permission.enabled === true) ||
        (permission.permission === "P_BANKING_UPLOAD_STATEMENTS" &&
          permission.enabled === true) ||
        (permission.permission === "P_BANKING_TXN_ACCOUNTING" &&
          permission.enabled === true) ||
        (permission.permission === "P_BANKING_TXN_MAPPING" &&
          permission.enabled === true)
      ) {
        if (!bankChecked) {
          userSideNav[5] = bankNav;
          bankChecked = true;
        }
      }
    });

  // profile
  userSideNav[6] = {
    _tag: "CSidebarNavItem",
    name: "Profile",
    to: "/profile",
    icon: navIcons.profile,
  };

  // Help and Support
  userSideNav[7] = {
    _tag: "CSidebarNavItem",
    name: "Help",
    to: "/help",
    icon: navIcons.help,
  };

  // logout
  userSideNav[8] = {
    _tag: "CSidebarNavItem",
    name: "Logout",
    to: "/logout",
    icon: navIcons.logOut,
  };

  // bottom side nav collapser
  userSideNav[9] = {
    _tag: "CSidebarNavDivider",
    className: "m-2",
  };

  return userSideNav;
};


// save paths to local to ristrict from entering not allowed path
export const saveUserPathsToLocal = (userNavigation , userPermissions ) => {

  const usernav = [];
  const userNavLinks = userNavigation ? userNavigation : [];

  if (userNavLinks.length > 0) {
        userNavLinks.map((navItem) => {
           console.log(navItem)
          if(navItem) {
             // getting direct route
            if (navItem.to) {
              if (
                navItem.to !== "/bank" &&
                navItem.to !== "/sales" &&
                navItem.to !== "/purchase"
              ) {
                usernav.push(navItem.to);
              }
            }
              // getting child route
            if (navItem._children) {
              navItem._children.map((item) => {
                if (item.to) {
                  usernav.push(item.to);
                }
                return "";
              });
            }
          }
          return "";
        });


        userPermissions.map((permission) => {

          if (permission.permission === "P_PUR_EXP_CREATE_ENTRIES" && permission.enabled === true) {
            usernav.push('/transaction/invoicedoc');
          }
          if (permission.permission === "P_SALES_CREATE_ENTRIES" && permission.enabled === true) {
            usernav.push('/transaction/invoicedocsales');
          }
           return ""
        })

        if (usernav.length > 0) {
          localStorage.setItem('@userPermittedRoutes' , JSON.stringify(usernav))
        } else {
          localStorage.setItem('@userPermittedRoutes' , JSON.stringify([]))
        }
  }  
}