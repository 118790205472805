export const agentDefinedRoutes = [
    "/purchase/purchaseinvoicesearch",
    "/sales/salesinvoicesearch",
    "/transaction/invoicedocsales",
    "/transaction/invoicedoc",
    "/profile",
    "/logout"
  ];



  