import { Auth } from 'aws-amplify';
import React from 'react'


export default class Logout extends React.Component {
  componentDidMount() {
    Auth.signOut({ })
    .then(async (result) =>{
        localStorage.clear();
        this.props.history.push('/');
    })
    .catch((err) => {});
  }

  render() {
    console.log(this.props);
    return null
  }
}
