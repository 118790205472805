 
import { Amplify } from 'aws-amplify';
import React, { Suspense, useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation  } from 'react-router-dom';
import awsConfig from './aws-exports';
import { AccountContext } from './Account';
import './scss/style.scss';
import NoSubscriptionPage from './views/subscriptions/pages/user/NoSubscritionPage';
import Logout from './views/pages/login/Logout';
import SubscriptionsPlansPage from './views/subscriptions/pages/admin/SubscriptionPlansPage';
import NoSubscriptionLayout from './views/subscriptions/components/NoSubscriptionLayout';
import AdminNoSubscriptionPage from './views/subscriptions/pages/admin/AdminNoSubscriptionPage';


// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const ForgetPassword = React.lazy(() => import('./views/pages/login/components/ForgetPassword'));
const OtpVerification = React.lazy(() => import('./views/pages/register/components/OtpVerificationStep'));



const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
//const MainPage = React.lazy(() => import('./views/dashboard/MainPage'));
const amplifyConfig = {
  ...awsConfig
}
amplifyConfig.aws_user_pools_id = process.env.REACT_APP_AWS_USER_POOL_ID
amplifyConfig.aws_user_pools_web_client_id = process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID

// console.log(amplifyConfig);

Amplify.configure({
  ...amplifyConfig
})


const LoadingUI = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)


// NOTE : DO NOT DELETE THE COMMENTED CODE BELOW. 


const App = () => {
  const location = useLocation();
 

  const {getSession} = useContext(AccountContext);
  const roleId = localStorage.getItem("roleId")

    useEffect(() => {
        getSession();
    },[]) 
 
 

  return (
    <React.Suspense fallback={LoadingUI}>

      <Switch>
        {localStorage.getItem('token') && localStorage.getItem('token') !== null
          ? 
           (roleId === 'ROLE_SUPER_ADMIN') || 
           (roleId === 'ROLE_SUPPORT_AGENT') || 
           (localStorage.getItem('@isActiveSubscription') === 'true') ?    
          <Switch>
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
            <Redirect from="/login" to={"/dashboard"} />
          </Switch>
            : 
             // subscription pages 
            (
              roleId === "ROLE_ADMIN" ? 
              <NoSubscriptionLayout>
              <Switch>
                <Route exact path="/nosubsription" name="No Subscription" render={props => <AdminNoSubscriptionPage {...props} />} />
                <Route exact path="/mysubscriptions/viewplans" name="Select Subscriptions" render={props => <SubscriptionsPlansPage {...props} />} />
                <Route exact path="/logout" name = "Logout" render={props => <Logout />} />
                <Redirect from={location.pathname} to={ "/nosubsription"   } />
              </Switch>
              </NoSubscriptionLayout>
             : 
             <NoSubscriptionLayout>
             <Switch>
              <Route exact path="/nosubscription" name="No Subscription" render={props => <NoSubscriptionPage {...props} />} />
              <Route exact path="/logout" name = "Logout" render={props => <Logout />} />
              <Redirect from={location.pathname} to={ "/nosubscription"} />
             </Switch>
             </NoSubscriptionLayout>
          ):
          // public pages
          <Suspense fallback={LoadingUI} >
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/forgotpassword" name="ForgetPassword" render={props => <ForgetPassword {...props} />} />
              <Route exact path="/otpVerification" name="OtpVerification" render={props => <OtpVerification {...props} />} />
              
              <Redirect from={location.pathname} to={'/login'} />
            </Switch>
          </Suspense>
        }
      </Switch>
    </React.Suspense>
  )
}
export default App;
