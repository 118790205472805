

const ButtonLoader = ({dark = false }) => {
     return  (
        <span
         className={"spinner-border spinner-border-sm text-light " }
        
          role="status" aria-hidden="true"></span>
     ) 
}

export default ButtonLoader