import React from 'react'
import CIcon from '@coreui/icons-react'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
const _nav2 = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Settings',
    route: '#',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'User Master',
        to: '/settings/users',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Tag Master',
        to: '/bank/tagmaster',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Bank Category',
        to: '/settings/categorymaster',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Company Profile',
        to: '/settings/companyprofile',
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Ledger',
    to: '/ledger',
    icon: <CIcon name="cil-list" customClasses="c-sidebar-nav-icon" />,
  },
  
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Masters',
    to: '/ledger',
    icon: <CIcon name="cil-list" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Default Ledgers',
        to: '/ledgers/defaultledgers',
      },
     
      {
        _tag: 'CSidebarNavItem',
        name: 'Supplier Master',
        to: '/ledgers/suppliermaster',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Customer Master',
        to: '/ledgers/customermaster',
      },
    ],
  },


  {
    _tag: 'CSidebarNavDropdown',
    name: 'Purchases & Expenses',
    route: '/purchase',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Upload Documents',
        to: '/purchase/purchasedocsearch',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Process Documents',
        to: '/purchase/purchaseinvoicesearch',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Sales',
    route: '/sales',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Upload Documents',
        to: '/sales/salesdocsearch',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Process Documents',
        to: '/sales/salesinvoicesearch',
      },

    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Bank',
    to: '/bank',
    icon: <CIcon name="cil-credit-card" customClasses="c-sidebar-nav-icon" />,
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Create Bank Account',
        to: '/bank/accountcreation',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Upload Bank Statement',
        to: '/bank/bankstatementupload',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Transaction Accounting',
        to: '/bank/accounttransaction',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Transaction Mapping',
        to: '/bank/reconcile',
      },
    ],
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'My Subscriptions',
    to : '/mysubscriptions',
    icon: <BookmarksOutlinedIcon className="c-sidebar-nav-icon" fontSize='small'/>,
  },
  
 {
    _tag: "CSidebarNavItem",
    name: "Help",
    to: "/help",
    icon: <HelpOutlineIcon className="c-sidebar-nav-icon" fontSize='small'/>,
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Logout',
    to: '/logout',
    icon: <CIcon name="cil-lock-locked" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: 'CSidebarNavDivider',
    className: 'm-2'
  }
]
export default _nav2

